import { changeMetalTypeFormat } from "../../../../shared/helpers/functions";
import "../styles.scss";
export default function Cards(props: any) {
  return (
    <div className="admin-CustomerScreen-card">
      <div className="admin-CustomerScreen-row">
        <div className="admin-CustomerScreen-txt8">{changeMetalTypeFormat(props?.type)}</div>
      </div>
      <div style={{ margin: 4 }} />
      <div className="admin-CustomerScreen-row" style={{ alignItems: "center" }}>
        <div>
          <div className="admin-CustomerScreen-txt6">Available Balance</div>
        </div>
        <div style={{ flex: 1 }} />
        <div className="admin-CustomerScreen-txt5">{props?.balance?.toFixed(2)} {props?.type==='cash'?'AED':'Gram'}</div>
      </div>
    </div>
  );
}
