import React from "react";
import { FiShoppingCart } from "react-icons/fi";
import { FcMoneyTransfer } from "react-icons/fc";

import goldCube from "../../../../assets/images/goldcube.png";
import silverCube from "../../../../assets/images/silvercube.png";
import { List } from "antd";
import { Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

function Section3(props: any) {
  const { t } = useTranslation();
  const liveRate = useSelector((state: any) => state.liveRate.current);

  const findBalance = (metalType: any) => {
    const metalItem: any = props?.data?.find(
      (item: any) => item.metal_type === metalType
    );
    return metalItem?.balance || 0;
  };

  const cash = findBalance("cash");

  const gold_995 = findBalance("gold_995");
  const gold_9999 = findBalance("gold_9999");
  const silver_999 = findBalance("silver_999");
  const gold_995Amount = gold_995 * liveRate[0]?.totalSellAmount;
  const SilverAmount = silver_999 * liveRate[1]?.totalSellAmount;
  const gold_9999Amount = gold_9999 * liveRate[2]?.totalSellAmount;

  const total = gold_995Amount + SilverAmount + gold_9999Amount + cash;

  return (
    <div className="app-WalletScreen-Section3">
      <div className="app-WalletScreen-Section1-Box1">
        <div className="app-WalletScreen-Section1-Box2">
          <FiShoppingCart />
        </div>
        <div>Total Asset</div>
        <div style={{ flex: 1 }}></div>
        <div className="app-WalletScreen-Section3-text1">
          {" "}
          ${total.toFixed(2)}&nbsp;
          <span className="WalletScreen-Section3-text2">AED</span>
        </div>
      </div>
      <br />
      <List
        itemLayout="horizontal"
        dataSource={props.data}
        bordered={false}
        loading={props.isLoading}
        renderItem={(item: any, index) => (
          <div className="WalletScreen-LogItems">
            <div className="WalletScreen-LogItems-icon">
              {item?.metal_type === "gold_995" ||
              item?.metal_type === "gold_9999" ? (
                <img src={goldCube} alt="gold_995" />
              ) : item?.metal_type === "silver_999" ? (
                <img src={silverCube} alt="silver_999" />
              ) : (
                <FcMoneyTransfer />
              )}
            </div>
            <div className="WalletScreen-LogItems2">
              <Row
                style={{
                  height: "100%",
                }}
                className="g-0"
              >
                <Col md={8} xs={6}>
                  <div className="WalletScreen-LogItems3">
                    {item?.metal_type === "gold_995" ||
                    item?.metal_type === "gold_9999" ||
                    item?.metal_type === "silver_999" ? (
                      <span className="WalletScreen-LogItemsText1">
                        {`${item?.balance.toFixed(2)} g`}
                      </span>
                    ) : item?.metal_type === "cash" ? (
                      <span className="WalletScreen-LogItemsText1">
                        {`${item?.balance.toFixed(2)} AED`}
                      </span>
                    ) : null}
                    <div
                      className="WalletScreen-LogItemsText2"
                      style={{ textTransform: "capitalize" }}
                    >
                      {item?.metal_type.replace(/_/g, " ")}
                    </div>
                  </div>
                </Col>
                <Col md={3} xs={6}>
                  <div className="WalletScreen-LogItems3">
                    {item?.metal_type === "gold_995" ? (
                      <div>AED {gold_995Amount.toFixed(2)}</div>
                    ) : item?.metal_type === "gold_9999" ? (
                      <div>AED {gold_9999Amount.toFixed(2)}</div>
                    ) : item?.metal_type === "silver_999" ? (
                      <div>AED {SilverAmount.toFixed(2)}</div>
                    ) : (
                      <div>AED {cash.toFixed(2)}</div>
                    )}
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        )}
      />
    </div>
  );
}

export default Section3;
