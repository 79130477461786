import {
  Button,
  DatePickerProps,
  Modal,
  Spin,
  Tag,
  message,
  notification,
} from "antd";
import { useEffect, useState } from "react";
import "../styles.scss";

import { LoadingOutlined } from "@ant-design/icons";

import { useForm } from "antd/es/form/Form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import dayjs from "dayjs";
import { metalType } from "../../../../shared/types/metalTypes";

import API from "../../../../config/api";
import { GET, POST } from "../../../../utlis/apiCalls";

import { Container } from "react-bootstrap";
import { useSelector } from "react-redux";
import { getInterval } from "../../../../shared/helpers/functions";
import moment from "moment";

export default function SipCreateModal(props: any) {
  const [form] = useForm();
  const { t } = useTranslation();

  const currency = useSelector((state: any) => state?.currencyInfo?.current);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLoading2, setIsLoading2] = useState<boolean>(false);

  const [notificationApi, contextHolder] = notification.useNotification();

  const [walletCash, setWalletCash] = useState<any>();
  const navigation = useNavigate();

  const [nobalance, setNobBalance] = useState<any>(false);

  useEffect(() => {
    LoadWallet();
  }, []);

  const LoadWallet = async () => {
    try {
      setIsLoading(true);
      const response: any = await GET(API.GET_WALLET + props?.User?.id, null);

      if (response?.status) {
        const wallet = response?.data;
        const cash = wallet?.find(
          (item: any) => metalType.cash === item?.metal_type
        );

        setWalletCash(cash);
        if (cash.balance < props?.sipData?.amount) {
          setNobBalance(true);
        } else {
          setNobBalance(false);
        }
      } else {
      }
    } catch (err) {
    } finally {
      setIsLoading(false);
    }
  };

  const formatter = (value: any) => {
    if (value === 0) {
      return "daily";
    }
    if (value === 33) {
      return "weekly";
    }
    if (value === 66) {
      return "monthly";
    }
    if (value === 100) {
      return "yearly";
    }
  };
  const handleSip = async (values: any) => {
    let paymentType = values?.payment_type;
    let obj = {
      user_id: props?.User?.id,
      amount: values?.amount / props?.currentInfo?.exchange_rate,
      tenure: props?.sipData?.year,
      interval: formatter(props?.sipData?.interval),
      payment_date: values?.payment_date,
      target_weight: props?.sipData?.weight * 1000,
      metal_type: props?.sipData?.metalType,
      payment_type: values?.payment_type,
    };
    try {
      setIsLoading2(true);
      console.log("obj", obj);
      console.log("first");
      const response: any = await POST(API.SIP_ADD, obj);

      console.log("response", response);
      if (paymentType === "card") {
        navigation("/auth/checkoutScreen", {
          state: {
            paid_module: "sip_create",
            module_id: response.data.sipHistoryId,
          },
        });
      } else {
        if (response?.status) {
          notificationApi.success({
            message:
              "Sip Request has been successfully processed, we will notify you once it has been completed",
            placement: "top",
          });
          form?.resetFields();
          props?.changeRefresh();
          props?.onClose();
        } else {
          notificationApi.error({
            message: response?.message,
          });
        }
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      setIsLoading2(false);
    }
  };

  const balance = () => {
    return (
      walletCash?.balance * props?.currentInfo?.exchange_rate -
      form?.getFieldValue("amount")
    );
  };
  function paymentDateHelper() {
    //conditon to check if interval is monthly
    const payment_date = form.getFieldValue("payment_date");
    if (payment_date) {
      if (props?.sipData?.interval === 33) {
        return <div>Your Payment Date is Every {payment_date}</div>;
      }
      //conditon to check if interval is monthly
      if (props?.sipData?.interval === 66) {
        return (
          <div>
            Your Payment Date is {payment_date.format("Do")} of Every Month{" "}
          </div>
        );
      }
      //conditon to check if interval is yearly
      if (props?.sipData?.interval === 100) {
        return (
          <div>
            Your Payment Date is {payment_date.format("Do")} /
            {payment_date.format("MMMM")} of Every Year
          </div>
        );
      }
    }
  }
  const disabledDate: DatePickerProps["disabledDate"] = (current) => {
    return current && current < dayjs().startOf("day");
  };

  const handleclick = async () => {
    try {
      setIsLoading2(true);
      setNobBalance(false);
      const obj = {
        metal_type: props?.sipData?.metalType,
        user_id: props?.User?.id,
        amount: props?.sipData?.amount,
        tenure: props?.sipData?.year,
        interval: getInterval(props?.sipData?.interval),
        target_weight: props?.sipData?.weight,
        payment_date: props?.sipData?.paymentDate,
      };
      const response: any = await POST(API.SIP_ADD, obj);
      if (response.status) {
        setIsLoading2(true);
        message.success("successfully initialated");
        props?.onClose();
        props?.changeRefresh();
      } else {
        setIsLoading2(false);
      }
    } catch {}
  };

  const getOrdinal = (day: any) => {
    if (day >= 11 && day <= 13) {
      return `${day}th`;
    }
    switch (day % 10) {
      case 1:
        return `${day}st`;
      case 2:
        return `${day}nd`;
      case 3:
        return `${day}rd`;
      default:
        return `${day}th`;
    }
  };
  return (
    <div>
      {contextHolder}
      <Modal
        open={props?.isOpen}
        onCancel={() => {
          form.resetFields();
          props?.onClose();
        }}
        width={400}
        className="app-SipScreen-SipCreateModal"
        // onOk={form?.submit}
        footer={false}
      >
        <div className="app-SipScreen-SipCreateModal-Box1">
          <Container fluid>
            <div className="app-SipScreen-SipCreateModal-Box3">
              <br />
              <br />
              <div className="app-SipScreen-SipCreateModal-Box1-txt1">
                Confirmation Purchase{" "}
              </div>
              <div>Payment Summary </div>
              <hr className="app-SipScreen-SipCreateModal-Underline" />
              <div className="app-SipScreen-SipCreateModal-Box2">
                <div>Metal type</div>
                <Tag className="app-SipScreen-SipCreateModal-Box2-Tag a">
                  {props?.sipData?.metalType}
                </Tag>
              </div>
              <div className="app-SipScreen-SipCreateModal-Box2">
                {" "}
                <div>Target gold weight</div>
                <Tag className="app-SipScreen-SipCreateModal-Box2-Tag b">
                  {props?.sipData?.weight} kg
                </Tag>
              </div>
              <div className="app-SipScreen-SipCreateModal-Box2">
                {" "}
                <div>Target tenur</div>
                <Tag className="app-SipScreen-SipCreateModal-Box2-Tag c">
                  {props?.sipData?.year} Y
                </Tag>
              </div>
              <div className="app-SipScreen-SipCreateModal-Box2">
                {" "}
                <div>Gold price</div>
                <Tag className="app-SipScreen-SipCreateModal-Box2-Tag d">{`${
                  currency.currency_code
                } ${props?.sipData?.amount.toFixed(2)}/${
                  props?.sipData?.interval === 66
                    ? "M"
                    : props?.sipData?.interval === 100
                    ? "Y"
                    : props?.sipData?.interval === 33
                    ? "W"
                    : "D"
                }`}</Tag>
              </div>
            </div>
          </Container>
          <div
            style={{
              width: "100%",
              borderBottom: "1px solid #f4f4f5",
              marginBottom: "10px",
            }}
          ></div>
          <div className="app-SipScreen-SipCreateModal-Duration">
            <span style={{ color: "red" }}>*</span>
            {props?.sipData?.interval === 66
              ? `The payment is processed on the ${getOrdinal(
                  moment(props?.sipData?.paymentDate).format("D")
                )} of each month.`
              : props?.sipData?.interval === 100
              ? `The payment is processed annually on ${moment(
                  props?.sipData?.paymentDate
                ).format("MMM")} ${getOrdinal(
                  moment(props?.sipData?.paymentDate).format("D")
                )}.`
              : props?.sipData?.interval === 33
              ? `The payment is processed every week on ${props?.sipData?.paymentDate}.`
              : ""}
          </div>
          {nobalance ? (
            <div className="app-SipScreen-SipCreateModal-Error">
              <strong style={{ color: "red" }}>Insufficiant balance,</strong>{" "}
              but you can still proceed with payment,It will reflect in your
              wallet after admin approval
            </div>
          ) : null}
          <div className="app-SipScreen-SipCreateModal-footer">
            <div>Continue with payment:</div>
            <Button onClick={handleclick}>
              {isLoading2 ? (
                <Spin
                  indicator={<LoadingOutlined color="#fff" spin />}
                  size="small"
                />
              ) : (
                `pay ${props?.sipData?.amount.toFixed(2)}`
              )}
            </Button>
          </div>
          <br />
        </div>
      </Modal>
    </div>
  );
}
